










































































































import ChatModel from "@/api/chat.model";
import MessageTemplateAppModel from "@/api/message_template_app.model";
import { IUser } from "@/interfaces/user";
import CustomButton from "@/layouts/components/Button.vue";
import Input from "@/layouts/components/Input.vue";
import Select from "@/layouts/components/Select.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Checkbox from "@/layouts/components/Checkbox.vue";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		Input,
		Checkbox,
	},
})
export default class ChooseHSM extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop() user!: IUser;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: false }) shouldLoadHSMWithChatChannelCorporateClient!: boolean;
	@Prop({ default: "" }) chatChannelId!: string;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	messageTemplateAppModel = MessageTemplateAppModel;
	chatModel = ChatModel;

	isMessageTemplateLoading: boolean = false;
	messageTemplatesOptions: any[] = [];
	messageTemplateIdFilter: string = "";
	currentMessageTemplate: any = {};
	parsedMessageTemplate: string = "";
	matchedValues: string[] = [];
	storedValues: { referenceKey?: string; value?: string }[] = [];
	buttonDisabled = true;
	buttonDisabledValidation: boolean[] = [];
	corporateClientIds: string[] = [];

	shouldNotUseAI = true;

	HSMOptions: { value: string; name: string }[] = [];

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
		await this.loadAllTemplates();
	}

	async loadAllTemplates() {
		const corporateClientIds = this.shouldLoadHSMWithChatChannelCorporateClient
			? [(await this.chatModel.getChannelInfo(this.chatChannelId)).corporateClient]
			: JSON.stringify(
					this.user.userCorporateClients.map((userCorporateClient: any) => userCorporateClient.corporateClient.id),
			  );
		this.HSMOptions = (await this.messageTemplateAppModel.list({ corporateClientIds })).map(
			(messageTemplate: any) => {
				return { name: messageTemplate.name, value: messageTemplate.id };
			},
		);
	}

	async changeTemplate() {
		this.matchedValues = [];
		if (this.messageTemplateIdFilter) {
			this.parsedMessageTemplate = "";
			this.storedValues = [];
			this.buttonDisabledValidation = [];
			this.currentMessageTemplate = await this.loadMessageTemplates(this.messageTemplateIdFilter);
			this.loadHSMVariables(this.currentMessageTemplate.text);
		}
	}

	async loadMessageTemplates(id: string) {
		return this.messageTemplateAppModel.getOne(id);
	}

	loadHSMVariables(text: string) {
		const specialCharacterRegex: RegExp = /\{\{(.*?)\}\}/gu;
		this.matchedValues = [...text.matchAll(specialCharacterRegex)].map((matchedValue: any) => matchedValue);
		this.matchedValues.forEach((value, index) => {
			this.buttonDisabledValidation[index] = false;
		});
	}

	replaceHSMVariableByReferenceKey() {
		let text = this.currentMessageTemplate.text;
		this.storedValues.forEach(storedValue => {
			const { referenceKey, value } = storedValue;
			if (referenceKey && value) {
				text = text.replace(referenceKey, value);
			}
		});

		this.parsedMessageTemplate = text;
		this.buttonDisabled = this.buttonDisabledValidation.every(validation => validation) ? false : true;
	}

	findFirstOccurrence(value: string, referenceKey: string, index: number) {
		if (value.length) {
			this.buttonDisabledValidation[index] = true;
			this.storedValues[index] = {};
			Object.assign(this.storedValues[index], { referenceKey, value });
		} else {
			this.buttonDisabledValidation[index] = false;
		}

		this.replaceHSMVariableByReferenceKey();
	}

	sendHsmMessage() {
		const hsmMessage = {
			id: this.messageTemplateIdFilter,
			message: this.parsedMessageTemplate,
			shouldNotUseAI: this.shouldNotUseAI,
		};
		this.$emit("sendHSM", hsmMessage);
	}
}
