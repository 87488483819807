
























































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

import { uploadChatChannelFile } from "@/api/_request";
import ChatModel, { GetDirectChannelDto, GetMessagesDto } from "@/api/chat.model";
import clientAppModel from "@/api/client_app.model";
import userAppModel from "@/api/user_app.model";
import { Client } from "@/entities/client";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import { getMask } from "@/helpers/utils";
import { ChatChannel, ChatMember, ChatMessage, ChatMessageAction, ChatMessageStatus } from "@/interfaces/chat";
import { IUser, UserWorkStatus } from "@/interfaces/user";
import ChatMemberCard from "@/layouts/components/ChatMemberCard.vue";
import ChatMessageCard from "@/layouts/components/ChatMessageCard.vue";
import ChooseChannel from "@/layouts/components/ChooseChannel.vue";
import ChooseClient from "@/layouts/components/ChooseClient.vue";
import ChooseUser from "@/layouts/components/ChooseUser.vue";
import ConfirmationModal from "@/layouts/components/ConfirmationModal.vue";
import CustomerProfile from "@/layouts/components/CustomerProfile.vue";
import GuideBalloon from "@/layouts/components/GuideBalloon.vue";
import Input from "@/layouts/components/Input.vue";
import MainChatMember from "@/layouts/components/MainChatMember.vue";
import MediaViewer from "@/layouts/components/MediaViewer.vue";
import OpenChannelWithNumber from "@/layouts/components/OpenChannelWithNumber.vue";
import UserCalendar from "@/layouts/components/UserCalendar.vue";
import UserCard from "@/layouts/components/UserCard.vue";
import {
	closeSocket,
	getTimestamp,
	initChat,
	sendMessageSocket,
	socketStatus,
	transferChannel,
} from "@/socket/webSocket";
import {
	BIconArrowLeftRight,
	BIconCaretRight,
	BIconChatLeftDots,
	BIconCheck2,
	BIconEmojiSmile,
	BIconPaperclip,
	BIconSearch,
	BSpinner,
	BootstrapVue,
	IconsPlugin,
} from "bootstrap-vue";
import debounce from "debounce";
import { VEmojiPicker } from "v-emoji-picker";
import ChooseHSM from "../../layouts/components/ChooseHSM.vue";
import ChooseStandardMessage from "../../layouts/components/ChooseStandardMessage.vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

@Component({
	components: {
		"chat-member-card": ChatMemberCard,
		"chat-message-card": ChatMessageCard,
		"journey-input": Input,
		BSpinner,
		UserCard,
		VEmojiPicker,
		BIconEmojiSmile,
		BIconPaperclip,
		BIconCaretRight,
		BIconChatLeftDots,
		BIconSearch,
		BIconArrowLeftRight,
		BIconCheck2,
		GuideBalloon,
		MainChatMember,
		"choose-client": ChooseClient,
		"choose-user": ChooseUser,
		"user-calendar": UserCalendar,
		"choose-hsm": ChooseHSM,
		"choose-standard-message": ChooseStandardMessage,
		"confirmation-modal": ConfirmationModal,
		"choose-channel": ChooseChannel,
		"open-channel-with-number": OpenChannelWithNumber,
		CustomerProfile,
		MediaViewer,
	},
})
export default class Chat extends Vue {
	model: any = clientAppModel;
	chatModel = ChatModel;
	userAppModel = userAppModel;
	searchParam: string = "";
	clientPhoneNumberText: string = "";
	clientHeader = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			content: [{ key: "phoneNumber" }],
		},
	];

	currentPage = 1;
	pageLimit = 15;

	activeUser: Partial<IUser> = {};

	clientsList: Client[] = [];
	channelList: ChatChannel[] = [];
	isClientsLoading = false;
	isChannelsLoading = false;
	isChatLoading = false;
	isChatOpened = false;
	clientPhoneNumber = "";
	mainChatChannelMember: { fullName: string; id: string } = {
		fullName: "",
		id: "",
	};
	messageText = "";
	emojiModalIsOpen = false;
	mainChatMemberModalIsOpen = false;
	currentChatChannel: ChatChannel;
	socket: any;

	messages: ChatMessage[] = [];
	foundClientId: string = "";
	customerProfileComponentKey: number = 0;
	mainChatMemberComponentKey: number = 0;
	channelListComponentKey: number = 0;
	sideToShow: "left" | "right" = "left";

	hsmModalIsOpen: boolean = false;
	standardModalIsOpen: boolean = false;
	clientModalIsOpen: boolean = false;
	userModalIsOpen: boolean = false;
	userCalendarIsOpen: boolean = false;
	openChannelWithNumberIsOpen: boolean = false;
	chooseChannelIsOpen: boolean = false;
	confirmationModalIsOpen: boolean = false;
	eventType: string = "";
	headerIconColor: string = "color: #1f9389;";

	shouldScrollBottom: boolean = true;

	debouncedGetMoreMessages = debounce(this.loadMoreMessages, 300);

	workStatusIconStyle: string = "h-7 font-bold";
	mediaPreview: any = "";
	shouldShowUploadPreview: boolean = false;
	uploadedFile: { fileName: string } | undefined = undefined;
	fileType: string | undefined = undefined;
	isMediaUploading = false;

	shouldShowMessageSendAlert = true;
	isMessageSendAlertModalOpen = false;
	uploadInputKey = 0;

	socketStatus = socketStatus;

	isReconnection = false;

	currentTime = new Date();

	currentTimeInterval: number | null = null;

	async uploadFile(event?: any, pastedFile?: File) {
		try {
			this.isMediaUploading = true;
			this.mediaPreview = "";
			const file = pastedFile ? pastedFile : event.target.files[0];

			this.fileType = file.type.split("/").pop();

			this.shouldShowUploadPreview = true;
			this.uploadedFile = await uploadChatChannelFile(file, this.currentChatChannel.id);
			this.isMediaUploading = false;
			if (this.uploadedFile) {
				this.mediaPreview = window.URL.createObjectURL(file);
				const journeyInput = <HTMLInputElement>document.getElementById("journey-input");
				journeyInput.focus();
			}
		} catch (error) {
			this.isMediaUploading = false;
			this.shouldShowUploadPreview = false;
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	unsetUploadFileVariables() {
		this.shouldShowUploadPreview = false;
		this.uploadedFile = undefined;
		this.uploadInputKey += 1;
	}

	async mounted() {
		try {
			this.updateCurrentTime();
			this.isChannelsLoading = true;
			await this.getUserProfile();
			await this.getChannels();
			await this.initSocket();
			this.isChannelsLoading = false;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isChannelsLoading = false;
		}
	}

	async getUserProfile() {
		this.activeUser = await this.userAppModel.getProfile();
		this.changeWorkStatusIconColor();
	}

	async initSocket(isReconnection = false) {
		this.socket = await initChat(this.$store.state.auth._token);

		this.isReconnection = isReconnection;

		this.socket.onmessage = async (event: any) => {
			const newMessage = JSON.parse(event.data);
			await this.callFunctionBySocketAction(newMessage, newMessage.action);
		};

		this.socket.onclose = async () => {
			this.socketReconnection();
		};
	}

	async socketReconnection() {
		closeSocket();
		await this.initSocket(true);
	}

	async callFunctionBySocketAction(newMessage: any, action: ChatMessageAction) {
		switch (action) {
			case ChatMessageAction.REGISTERED:
				console.info(getTimestamp(), "[WS] Iniciando canal");
				if (this.isReconnection) {
					await this.getChannels();
					if (this.currentChatChannel) {
						await this.startConversation(this.currentChatChannel, this.isReconnection);
					}
					this.isReconnection = false;
				}
				break;
			case ChatMessageAction.STATUS_CHANGE:
				this.updateChatMessageStatus(newMessage);
				break;
			case ChatMessageAction.NEW_MESSAGE:
				await this.addNewChatMessage(newMessage);
				break;
			case ChatMessageAction.REFRESH_CHANNELS:
				if (newMessage.chatChannel) {
					showSuccessAlert("Canal recebido por transferência adicionado na listagem.");
				}
				this.isChannelsLoading = true;
				await this.getChannels();
				if (this.currentChatChannel && !this.channelList.find(channel => channel.id === this.currentChatChannel.id)) {
					this.updateChannelList();
					this.foundClientId = "";
					this.customerProfileComponentKey += 1;
				}
				this.reloadChatChannelListComponent();
				this.isChannelsLoading = false;

				break;
			case ChatMessageAction.ACKNOWLEDGED:
				const { message } = newMessage;
				if (newMessage.message && message.chatChannel === this.currentChatChannel.id) {
					this.updateChannelList();
					await this.getChannels();
					this.reloadChatChannelListComponent();
					this.isChannelsLoading = false;
					showSuccessAlert("Canal transferido com sucesso.");
				}
				break;
			case ChatMessageAction.ERROR:
				const { error } = newMessage;
				showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
				break;
			default:
				break;
		}
	}

	updateChannelList() {
		this.isChannelsLoading = true;
		this.isChatOpened = false;
		this.messages = [];
	}

	updateChatMessageStatus(newMessage: any) {
		if (newMessage.chatChannel === this.currentChatChannel?.id) {
			const foundMessageIndex = this.messages.findIndex(message => message.id === newMessage.id);
			if (foundMessageIndex !== -1 && this.messages[foundMessageIndex].status !== ChatMessageStatus.READ) {
				this.messages[foundMessageIndex].status = newMessage.status;
			}

			if (newMessage.status === ChatMessageStatus.UNDELIVERED) {
				//Possível problema de envio de HSM com o canal já liberado para mensagens livres
				showErrorAlert("Falha no envio da mensagem");
			}
		}
	}

	async assignMessageAndUpdateList(message: any, index: number) {
		this.channelList[index].lastChatMessage = message;
		const updatedChannel = this.channelList[index];
		this.channelList.splice(index, 1);
		const lastUnpinnedChannelIndex = this.channelList.findIndex(channel => !channel.channelFixationDate);

		if (!updatedChannel.channelFixationDate && lastUnpinnedChannelIndex !== -1) {
			this.channelList.splice(lastUnpinnedChannelIndex, 0, updatedChannel);
		} else if (!updatedChannel.channelFixationDate) {
			this.channelList.unshift(updatedChannel);
		} else if (updatedChannel.channelFixationDate) {
			this.channelList.splice(index, 0, updatedChannel);
		}
	}

	async addNewChatMessage(message: any) {
		const foundChannelIndex = this.channelList.findIndex(channel => channel.id === message.chatChannel);
		if (foundChannelIndex !== -1 && this.channelList[foundChannelIndex].id === this.currentChatChannel?.id) {
			this.messages.push(message);
			await this.assignMessageAndUpdateList(message, foundChannelIndex);
		} else if (foundChannelIndex !== -1) {
			await this.assignMessageAndUpdateList(message, foundChannelIndex);
		} else if (foundChannelIndex === -1 && this.currentChatChannel?.id === message?.chatChannel) {
			this.messages.push(message);
			await this.getChannels();
		} else {
			await this.getChannels();
		}
	}

	async getChannels() {
		this.channelList = await this.chatModel.getChannels({ shouldIgnoreChannelStatus: true });
	}

	async getMessages(getMessagesDto: GetMessagesDto) {
		return this.chatModel.getMessages(getMessagesDto);
	}

	async updateChannelMessagesStatus(getMessagesDto: GetMessagesDto) {
		return this.chatModel.updateChannelMessagesStatus(getMessagesDto);
	}

	async createOrOpenChatChannel(getDirectChannelDto: GetDirectChannelDto) {
		return this.chatModel.getDirectChannel(getDirectChannelDto);
	}

	async bindClientsToChannel(clients: string[], chatChannel: string) {
		return this.chatModel.bindClientsToChannel(clients, chatChannel);
	}

	async pinChatChannelOnQueue(chatChannel: string) {
		return this.chatModel.pinChatChannelOnQueue(chatChannel);
	}

	async searchClient(event: Event) {
		try {
			this.isClientsLoading = true;
			const searchParam = this.searchParam?.length ? this.searchParam : undefined;
			const request = await this.model.getClientList({
				page: 1,
				limit: 15,
				sort: {
					property: "client.name",
					type: "ASC",
				},
				searchParam,
			});
			this.clientsList = request.data;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isClientsLoading = false;
		}
	}

	async loadMoreMessages() {
		if (!this.shouldShowUploadPreview) {
			try {
				const scrollContainerElement = document.getElementById("scrollContainer");

				if (this.isChatOpened && scrollContainerElement && scrollContainerElement.scrollTop === 0) {
					scrollContainerElement.scrollTop = 10;
					const lastMessageCreatedDate = this.messages[0].createdDate;
					const chatChannel = this.currentChatChannel.id;
					this.shouldScrollBottom = false;
					const foundMessages = (
						await this.getMessages({ chatChannel, lastChatMessageDate: lastMessageCreatedDate })
					).reverse();
					if (foundMessages.length) {
						this.messages.unshift(...foundMessages);
					}
				}
			} catch (error) {
				showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
			}
		}
	}

	scrollToBottom() {
		setTimeout(() => {
			const element = document.getElementById("chatContainer");
			if (element && this.shouldScrollBottom) {
				element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			}
			this.shouldScrollBottom = true;
		}, 1000);
	}

	updated() {
		this.scrollToBottom();
	}

	changeShowMessageAlertStatus() {
		this.shouldShowMessageSendAlert = false;
		const journeyInput = <HTMLInputElement>document.getElementById("journey-input");
		journeyInput.focus();
	}

	changeInputHeight(text: string) {
		const journeyInput = <HTMLInputElement>document.getElementById("journey-input");
		if (journeyInput.scrollHeight < 250) {
			journeyInput.style.height = `${journeyInput.scrollHeight}px`;
			if (!text.length || text.length < 105) {
				journeyInput.style.height = "45px";
			}
		} else if (journeyInput.scrollHeight >= 250) {
			journeyInput.style.height = "250px";
			if (!text.length || text.length < 105) {
				journeyInput.style.height = "45px";
			}
		}
	}

	sendMessage(event?: KeyboardEvent, hsm?: { id: string; message: string; shouldNotUseAI: boolean } | undefined) {
		//Caso não tenha a conversa liberada, Obrigatório messageTemplate
		if (event?.shiftKey) {
			this.messageText += "\n";
			this.changeInputHeight(this.messageText);
		} else {
			try {
				const mediaFiles: string[] | undefined = this.uploadedFile ? [this.uploadedFile.fileName] : undefined;
				if (!mediaFiles?.length || (mediaFiles?.length && !this.shouldShowMessageSendAlert)) {
					const text: string = hsm?.message ?? this.messageText;
					const metadata: undefined = undefined;
					const messageTemplate: string | undefined = hsm?.id ?? undefined;
					this.shouldShowMessageSendAlert = true;
					if (this.currentChatChannel) {
						sendMessageSocket(
							this.currentChatChannel.id,
							text,
							mediaFiles,
							metadata,
							messageTemplate,
							hsm ? !hsm?.shouldNotUseAI : undefined,
						);
						this.messageText = "";
						this.unsetUploadFileVariables();
						this.changeInputHeight("");
					}
				} else if (this.shouldShowMessageSendAlert && mediaFiles?.length) {
					this.isMessageSendAlertModalOpen = true;
				}
			} catch (error) {
				showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
			} finally {
			}
		}
	}

	validateIsWithinAllowedNonTemplateMessages(then?: Date) {
		const now = new Date();
		const isWithinTheAllowedTime = then
			? !!(Math.abs(new Date(then).getTime() - now.getTime()) / (60 * 60 * 1000) < 24)
			: false; // Diferença em horas entre duas datas
		return isWithinTheAllowedTime;
	}

	async startConversation(chatChannel: ChatChannel, isReconnection?: boolean) {
		try {
			this.isChatLoading = true;
			this.emojiModalIsOpen = false;
			this.clientModalIsOpen = false;
			this.mainChatMemberModalIsOpen = false;
			this.currentChatChannel = chatChannel;
			this.mainChatChannelMember.id = "";
			this.mainChatChannelMember.fullName = "";
			this.messages = (await this.getMessages({ chatChannel: chatChannel.id })).reverse();
			const isMessagesUpdated = await this.updateChannelMessagesStatus({ chatChannel: chatChannel.id });

			if (isMessagesUpdated) {
				this.currentChatChannel.lastChatMessage.status = ChatMessageStatus.READ;
			}
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isChatLoading = false;
			this.isChatOpened = true;
			this.clientPhoneNumber = this.currentChatChannel.phoneNumber;
			const foundChatMember = this.currentChatChannel.chatMembers.find(member => member.isMainMember);
			if (foundChatMember) {
				this.mainChatChannelMember.id = foundChatMember.id;
				this.mainChatChannelMember.fullName = foundChatMember?.client
					? this.formatClientFullName(foundChatMember.client)
					: "";
				if (typeof isReconnection === "boolean" && isReconnection) {
					this.isReconnection = false;
					return;
				}
				this.openClientProfile(foundChatMember.client!);
			} else {
				if (typeof isReconnection === "boolean" && isReconnection) {
					this.isReconnection = false;
					return;
				}
				this.customerProfileComponentKey += 1;
				this.foundClientId = "";
			}

			this.isReconnection = false;
		}
	}

	formatClientFullName(client: Partial<Client>) {
		return `${client.name}${client.lastName ? " " + client.lastName : ""}`;
	}

	async createOrOpenConversationOnlyWithPhoneNumber(
		phoneNumber: string,
		shouldSendReceptiveMessage: boolean,
		corporateClientId: string,
	) {
		await this.createOrOpenConversation(<Partial<Client>>phoneNumber, shouldSendReceptiveMessage, corporateClientId);
	}

	async createOrOpenConversation(
		client: Partial<Client>,
		shouldSendReceptiveMessage?: boolean,
		corporateClientId?: string,
		chatChannelId?: string,
	) {
		try {
			if (this.clientModalIsOpen) {
				this.clientModalIsOpen = false;
			}
			if (!client.phoneNumber) {
				return;
			}
			if (
				this.currentChatChannel &&
				(chatChannelId
					? this.currentChatChannel.phoneNumber === client.phoneNumber &&
					  this.currentChatChannel.corporateClient.id === corporateClientId
					: this.currentChatChannel.phoneNumber === client.phoneNumber) &&
				this.isChatOpened
			) {
				return;
			}
			this.isChatLoading = true;
			const newChatChannel = await this.createOrOpenChatChannel({
				client: client?.id,
				phoneNumber: client.phoneNumber,
				shouldSendReceptiveMessage,
				corporateClientId: corporateClientId,
				chatChannelId,
			});
			const foundChannelInList = this.channelList?.find(channel => channel.id === newChatChannel.id);
			if (!foundChannelInList) {
				await this.getChannels();
			}
			await this.startConversation(newChatChannel);
			this.isChatOpened = true;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.searchParam = "";
			this.clientsList = [];
			this.isChatLoading = false;
			this.userCalendarIsOpen = false;
		}
	}

	async relateClientToChannel(client: Client) {
		try {
			if (this.clientModalIsOpen) {
				this.clientModalIsOpen = false;
			}

			if (this.currentChatChannel) {
				const [chatMember] = await this.bindClientsToChannel([client.id], this.currentChatChannel.id);
				this.currentChatChannel.chatMembers.push(chatMember);
				showSuccessAlert("Relação adicionada com sucesso.");
			} else {
				showErrorAlert("Abra um canal antes de víncular um cliente.");
			}
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
		}
	}

	openClientProfile(client: Client) {
		if (this.clientModalIsOpen) {
			this.clientModalIsOpen = false;
		}

		this.customerProfileComponentKey += 1;
		this.foundClientId = client.id;
	}

	reloadCustomerProfileComponent() {
		this.customerProfileComponentKey += 1;
	}

	reloadMainChatMemberComponent() {
		this.mainChatMemberComponentKey += 1;
	}

	reloadChatChannelListComponent() {
		this.channelListComponentKey += 1;
	}

	selectEmoji(emoji: { category: string; data: string }) {
		this.messageText += emoji.data;
		this.changeEmojiModalStatus(false);
	}

	changeEmojiModalStatus(status: boolean) {
		this.emojiModalIsOpen = status;
	}

	changeMainChatMemberModalStatus(status: boolean) {
		this.mainChatMemberModalIsOpen = status;
	}

	closeModal() {
		this.hsmModalIsOpen = false;
		this.standardModalIsOpen = false;
		this.clientModalIsOpen = false;
		this.userModalIsOpen = false;
		this.userCalendarIsOpen = false;
		this.confirmationModalIsOpen = false;
		this.chooseChannelIsOpen = false;
		this.isMessageSendAlertModalOpen = false;
		this.openChannelWithNumberIsOpen = false;
	}

	openClientSearchModal(eventType: string) {
		this.eventType = eventType;
		this.clientModalIsOpen = true;
	}

	openUserSearchModal() {
		this.userModalIsOpen = true;
	}

	openConfirmationModal() {
		this.confirmationModalIsOpen = true;
	}

	inputMask() {
		return getMask("tel");
	}

	updateChannelMembersMainStatus(updatedChatMembers: ChatMember[]) {
		this.channelList
			.find(channel => channel.id === this.currentChatChannel.id)
			?.chatMembers.forEach(member => {
				const foundUpdatedChatMember = updatedChatMembers.find(
					updatedChatMember => updatedChatMember.id === member.id,
				);
				if (foundUpdatedChatMember) {
					member.isMainMember = foundUpdatedChatMember.isMainMember;
				}
			});
		this.channelListComponentKey += 1;
	}

	async mainChatMemberActions(data: ChatMember, action: "delete" | "update") {
		try {
			if (action === "update") {
				const updatedChatMembers: ChatMember[] = await this.chatModel.setChatChannelMainClientMember(
					data.id,
					this.currentChatChannel.id,
				);

				this.currentChatChannel.chatMembers.forEach((chatMember, chatMemberIndex) => {
					const foundChatMember = updatedChatMembers.find(
						updatedChatMember => updatedChatMember.id === chatMember.id,
					);
					if (foundChatMember) {
						this.currentChatChannel.chatMembers[chatMemberIndex].isMainMember = foundChatMember.isMainMember;
					}
				});
				const newMainChatChannelMember = this.currentChatChannel.chatMembers.find(
					updatedChatMember => updatedChatMember.isMainMember,
				);
				if (newMainChatChannelMember) {
					this.mainChatChannelMember.id = newMainChatChannelMember!.id;
					this.mainChatChannelMember.fullName = newMainChatChannelMember?.client
						? this.formatClientFullName(newMainChatChannelMember.client)
						: "";
				} else {
					this.mainChatChannelMember.id = "";
					this.mainChatChannelMember.fullName = "";
				}
				this.updateChannelMembersMainStatus(updatedChatMembers);
				this.mainChatMemberModalIsOpen = !this.mainChatMemberModalIsOpen;
				if (newMainChatChannelMember) {
					this.openClientProfile(newMainChatChannelMember!.client!);
				}

				showSuccessAlert("Alteração realizada com sucesso.");
			} else if (action === "delete") {
				const response: boolean = await this.chatModel.deleteChatChannelMainClientMember(
					data.id,
					this.currentChatChannel.id,
				);
				if (response) {
					const currentMembers = this.channelList.find(
						channel => channel.id === this.currentChatChannel.id,
					)?.chatMembers;
					const foundChatMemberIndex = currentMembers
						? currentMembers.findIndex(member => member.id === data.id)
						: -1;

					if (foundChatMemberIndex !== -1 && currentMembers) {
						currentMembers.splice(foundChatMemberIndex, 1);
						this.currentChatChannel.chatMembers = currentMembers;
						this.reloadMainChatMemberComponent();
						this.updateChannelMembersMainStatus(currentMembers);
					}
					if (this.mainChatChannelMember.id === data.id) {
						this.mainChatChannelMember.id = "";
						this.mainChatChannelMember.fullName = "";
					}
					this.mainChatMemberModalIsOpen = !this.mainChatMemberModalIsOpen;
				}

				showSuccessAlert("Relação removida com sucesso.");
			}

			// this.mainChatMemberModalIsOpen = false;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
		}
	}

	get chatMembersList() {
		return this.currentChatChannel.chatMembers;
	}

	openUserCalendarModal() {
		this.userCalendarIsOpen = true;
	}

	async updateUserWorkStatus() {
		try {
			const newStatusValue: UserWorkStatus =
				this.activeUser.workStatus === UserWorkStatus.ACTIVE ? UserWorkStatus.AWAY : UserWorkStatus.ACTIVE;
			const updatedUser = await this.userAppModel.updateProfile({ workStatus: newStatusValue });
			this.activeUser.workStatus = updatedUser.workStatus;
			this.changeWorkStatusIconColor();
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			showSuccessAlert("Status alterado com sucesso.");
		}
	}

	changeWorkStatusIconColor() {
		this.workStatusIconStyle =
			this.activeUser.workStatus === UserWorkStatus.ACTIVE
				? "h-7 font-bold text-journey-emerald-green"
				: "h-7 font-bold text-journey-red";
	}

	sendHSM(hsmMessage: { id: string; message: string; shouldNotUseAI: boolean }) {
		this.sendMessage(undefined, hsmMessage);
		this.hsmModalIsOpen = false;
	}

	chooseStandardMessage(standardMessage: { id: string; text: string }) {
		const journeyInput = <HTMLInputElement>document.getElementById("journey-input");
		journeyInput.value = "";
		journeyInput.value = standardMessage.text;
		this.changeInputHeight(standardMessage.text);
		this.messageText = standardMessage.text;
		journeyInput.setAttribute("value", standardMessage.text);
		journeyInput.focus();
		this.standardModalIsOpen = false;
	}

	async pinChannel(channel: ChatChannel) {
		const successMessage = channel.channelFixationDate
			? "Canal desafixado com sucesso."
			: "Canal fixado com sucesso.";
		try {
			const response = await this.pinChatChannelOnQueue(channel.id);
			if (response) {
				this.isChannelsLoading = true;
				await this.getChannels();
				this.reloadChatChannelListComponent();
				this.isChannelsLoading = false;
				showSuccessAlert(successMessage);
			}
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	async transferChannel(cardData: IUser) {
		try {
			transferChannel(this.currentChatChannel.id, cardData.id!);
			this.foundClientId = "";
			this.customerProfileComponentKey += 1;
			this.userModalIsOpen = false;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	async closeChannel() {
		try {
			const currentChatChannel = this.currentChatChannel;
			const response = await this.chatModel.closeChannel(currentChatChannel.id);
			if (response) {
				this.currentChatChannel.id = "";
				this.updateChannelList();
				await this.getChannels();
				this.reloadChatChannelListComponent();
				this.isChannelsLoading = false;
				this.foundClientId = "";
				this.customerProfileComponentKey += 1;
				showSuccessAlert("Canal encerrado com sucesso.");
			}
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	formatSubTitleConfirmationModal() {
		if (this.currentChatChannel) {
			return `A conversa${
				this.mainChatChannelMember.fullName
					? " com o cliente <b class='text-journey-black'>" + this.mainChatChannelMember.fullName + "</b>"
					: ""
			} referente ao número <b class="text-journey-black">${
				this.currentChatChannel.phoneNumber
			}</b> será encerrada. Clique em sim para confirmar a operação.`;
		}

		return "";
	}

	async getImage(event: any) {
		let items = event.clipboardData.items;
		for (let itemIndex in items) {
			let item = items[itemIndex];

			if (item.kind === "file") {
				await this.uploadFile(undefined, item.getAsFile());
			}
		}
	}

	get propCurrentTime() {
		return this.currentTime;
	}

	updateCurrentTime() {
		this.currentTimeInterval = null;
		this.currentTimeInterval = setInterval(() => {
			this.currentTime = new Date();
		}, 30000);
	}

	beforeDestroy() {
		if (this.currentTimeInterval) {
			clearInterval(this.currentTimeInterval);
			this.currentTimeInterval = null;
		}
	}
}
